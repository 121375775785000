import React, { useState } from 'react';
import './Footer.scss';

function Footer() {

	const [footerIcons] = useState([
		{
			id: 1,
			icon: 'fa fa-github',
			title: 'View my GitHub',
			link: "https://github.com/HragA",
			alt: "My Github"
		},
		{
			id: 2,
			icon: 'fa fa-linkedin-square',
			title: 'View my LinkedIn',
			link: "https://www.linkedin.com/in/hrag-ayvazian/",
			alt: "My LinkedIn"
		},
		{
			id: 3,
			icon: 'fa fa-envelope',
			title: 'Email Me',
			link: "mailto:hraghovsep@gmail.com",
			alt: "Email Me"
		}
	]);

	return (
		<div className="FooterBody">
			{footerIcons.map(footerIcon => (
				<a
					key={footerIcon.id}
					href={footerIcon.link}
					target="_blank"
					alt={footerIcon.alt}
					rel="noopener noreferrer"
				>
					<i
						className={footerIcon.icon}
						data-toggle="tooltip"
						data-placement="top"
						title={footerIcon.title}
						rel="noopener noreferrer"
					/>
				</a>
			))}
		</div>
	);
}

export default Footer;