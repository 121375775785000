import React, { useState, useEffect } from 'react';
import {
	VerticalTimeline,
	VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Experience.scss';

import agency from '../../assets/experience-logos/theAgency/TheAgencyLogo_PMS.png';
import autopro from '../../assets/experience-logos/autopro/Auto-Pro-Repair.png';
import CSUN from '../../assets/school/CSUN_Seal.png';
import liferay from '../../assets/experience-logos/liferay/liferay-icon.svg';

function Experience() {

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	function handleWindowSizeChange() {
		setIsMobile(window.innerWidth <= 768);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	return (
		<div className="ExperienceBody">
			<h1>Experience</h1>
			<VerticalTimeline animate={!isMobile}>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date="November 2020 - Present"
					icon={<img src={liferay} alt="Liferay" />}
				>
					<h3 className="vertical-timeline-element-title">Liferay</h3>
					<h4 className="vertical-timeline-element-subtitle">Software Engineer</h4>
					<p>
						Currently developing new features to improve the sales
						representatives' day to day operations and
						creating/updating API requests to send customer information
						to other Liferay services.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date="September 2018 - March 2020"
					icon={<img src={agency} alt="The Agency RE" />}
				>
					<h3 className="vertical-timeline-element-title">The Agency RE</h3>
					<h4 className="vertical-timeline-element-subtitle">Junior Software Developer</h4>
					<p>
						Developed & enhanced the Customer Relationship Management (CRM) platform.
						I also obtained & was managing the Marketing/eBlast Wizards.
						Using popular technologies such as Angular & the Salesforce platform.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date="September 2011 - August 2018"
					icon={<img src={autopro} alt="Auto Pro Repair" />}
				>
					<h3 className="vertical-timeline-element-title">Auto Pro Repair</h3>
					<h4 className="vertical-timeline-element-subtitle">Full Stack Web Developer</h4>
					<p>
						Developed and maintaining the website.
						As well as conducting computer management.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date="August 2014 - May 2018"
					icon={<img src={CSUN} alt="California State University, Northridge" />}
				>
					<h3 className="vertical-timeline-element-title">California State University, Northridge</h3>
					<h4 className="vertical-timeline-element-subtitle">Bachelors of Science in Computer Science</h4>
					<p>
						Graduated with Honors
					</p>
				</VerticalTimelineElement>
			</VerticalTimeline>
		</div>
	);
}

export default Experience;