import React, { useState } from 'react';
import ProjectCard from './ProjectCard';
import { Container, Row, Col } from 'reactstrap';
import './Projects.scss';

function Projects() {

	const [projects] = useState([
		{
			id: 8,
			name: 'Personal Website',
			description: 'Personal website created using React 17, and Reactstrap.',
			github: '',
			languages: ['React']
		},
		{
			id: 7,
			name: 'Weather',
			description: 'This online weather application is built using React and getting weather information from the MetaWeather API.',
			github: 'https://github.com/HragA/Weather',
			languages: ['React']
		},
		{
			id: 6,
			name: 'News Items',
			description: 'This is a news article system created using Magnolia Cli with Vue.js for the frontend and python for the backend.',
			github: 'https://github.com/HragA/News-Items',
			languages: ['Magnolia CLI', 'Vue.js', 'Python']
		},
		{
			id: 5,
			name: 'Ticket System',
			subtitle: 'Software Developer',
			description: 'Online ticket system to take the ease off paper ticket systems. Created using the MERN (MongoDB, Express.js, React, Node.js) Stack.',
			github: 'https://github.com/HragA/Ticket-System',
			languages: ['MongoDB', 'Express.js', 'React', 'Node.js']
		},
		{
			id: 4,
			name: 'MERN Login',
			description: 'Boiler plate for a login system using the MERN (MongoDB, Express.js, React, Node.js) Stack.',
			github: 'https://github.com/HragA/MERN_Login',
			languages: ['MongoDB', 'Express.js', 'React', 'Node.js']
		},
		{
			id: 3,
			name: '4 Years',
			description: 'An online application centered around college students that creates a plan to help them potentially graduate in four years.',
			github: '',
			languages: ['MongoDB', 'Express.js', 'Angular', 'Node.js']
		},
		{
			id: 2,
			name: 'Stock Forecast',
			description: 'Stock prediction application to tell you the best time to buy and sell stocks using Yahoo Finance API.',
			github: 'https://github.com/HragA/Stock-Forecast',
			languages: ['Java']
		},
		{
			id: 1,
			name: 'Hangman',
			description: 'Hangman game where a random word is selected from a given list and you need guess the word. This application is created with Java GUI.',
			github: 'https://github.com/HragA/Hangman',
			languages: ['Java']
		}
	]);

	const numCol = 3;
	const rows = [...Array(Math.ceil(projects.length / numCol))];
	const productRows = rows.map((row, index) => (
		projects.slice(index * numCol, index * numCol + numCol)));

	return (
		<div className="ProjectsHome">
			<h1>Projects</h1>
			<Container fluid>
				{productRows.map((row, index) => (
					<Row key={index}>
						{row.map(project => (
							<Col sm="4" key={project.id}>
								<ProjectCard project={project} />
							</Col>
						))}
					</Row>
				))}
			</Container>
		</div>
	);
}

export default Projects;