import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './Home.scss';

import AboutMe from '../aboutMe/AboutMe';
import Experience from '../experience/Experience';
import HomeTop from '../homeTop/HomeTop';
import Skills from '../skills/Skills';

function Home() {

	const history = useHistory();

	return (
		<div className="HomeBody">
			<HomeTop />
			<AboutMe />
			<Skills />
			<Experience />
			<div className="viewProjectsBtn">
				<Button outline onClick={() => history.push('/projects')}>
					<i className="fa fa-code"></i> View Projects
				</Button>
			</div>
		</div>
	);
}

export default Home;