import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import NavbarCollapse from './NavbarCollapse';
import './Navbar.scss';

function Navbar() {

	const [hideNav, setHideNav] = useState(window.innerWidth <= 760);
	const [navBackgroundColor, setNavBackgroundColor] =
		useState(hideNav ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)');

	useEffect(() => {
		const hideNavHandler = () => {
			setHideNav(window.innerWidth <= 760);
			if (hideNav) {
				setNavBackgroundColor('rgba(0, 0, 0, 1)');
			} else {
				navbarColorHandler();
			}
		};

		const navbarColorHandler = () => {
			if (window.scrollY > 80 || hideNav) {
				setNavBackgroundColor('rgba(0, 0, 0, 1)');
			} else {
				setNavBackgroundColor(`rgba(0, 0, 0,
					${((1 - (80 - window.scrollY) / 80) * 0.8)})`);
			}
		};

		window.addEventListener("resize", hideNavHandler);
		window.addEventListener('scroll', navbarColorHandler);
	}, [hideNav]);

	function collapsedNav() {
		if (hideNav) {
			return <NavbarCollapse />;
		} else {
			return (
				<div className="rightSide">
					<NavLink className="rightSideLink" to="/projects"
						activeStyle={{ color: '#DAA520' }}>

						Projects
					</NavLink>
				</div>
			);
		}
	}

	return (
		<div className="Navbar" style={{ backgroundColor: navBackgroundColor }}>
			<NavLink className="leftSide" to="/">Home</NavLink>
			{collapsedNav()}
		</div>
	);
}

export default Navbar;