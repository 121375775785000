import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import SwitchRouter from './router/SwitchRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'devicon';
import './App.scss';

function App() {

	return (
		<div className="App">
			<Navbar />
			<SwitchRouter />
			<Footer />
		</div>
	);
}

export default App;
