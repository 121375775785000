import React from 'react';
import {
	Card, CardText, CardBody,
	CardTitle, Col, Row
} from 'reactstrap';

function ProjectCard(props) {

	return (
		<div className="ProjectCard">
			<Card key={props.project.id}>
				<CardBody>
					<CardTitle>{props.project.name}</CardTitle>
					<CardText>{props.project.description}</CardText>
				</CardBody>
				{!!props.project.github ? <a href={props.project.github} target="_blank" rel="noopener noreferrer">
					<i className="devicon-github-plain"></i>
				</a> : ''}
				<Row className="CardLanguages">
					{props.project.languages.map(language => (
						<Col key={props.project.languages.indexOf(language)}>
							<small className="text-muted">{language}</small>
						</Col>
					))}
				</Row>
			</Card>
		</div>
	);
}

export default ProjectCard;