import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Skills.scss';

function Skills() {

	const [skills] = useState([
		{
			id: 1,
			icon: 'devicon-html5-plain',
			name: 'HTML'
		},
		{
			id: 2,
			icon: 'devicon-css3-plain',
			name: 'CSS'
		},
		{
			id: 3,
			icon: 'devicon-javascript-plain',
			name: 'Javascript'
		},
		{
			id: 4,
			icon: 'devicon-react-original',
			name: 'React'
		},
		{
			id: 5,
			icon: 'devicon-java-plain',
			name: 'Java'
		},
		{
			id: 6,
			icon: 'devicon-python-plain',
			name: 'Python'
		},
		{
			id: 7,
			icon: 'devicon-angularjs-plain',
			name: 'Angular'
		},
		{
			id: 8,
			icon: 'devicon-nodejs-plain',
			name: 'Node.js'
		},
		{
			id: 9,
			icon: 'devicon-php-plain',
			name: 'PHP'
		},
		{
			id: 10,
			icon: 'devicon-mysql-plain',
			name: 'MySQL'
		},
		{
			id: 11,
			icon: 'devicon-mongodb-plain',
			name: 'MongoDB'
		}
	]);

	const numCol = 4;
	const rows = [...Array(Math.ceil(skills.length / numCol))];
	const skillRows = rows.map((row, index) => (
		skills.slice(index * numCol, index * numCol + numCol)));

	return (
		<div className="SkillsBody">
			<h1>Skills</h1>
			<Container fluid>
				{skillRows.map((row, index) => (
					<Row key={index}>
						{row.map(skill => (
							<Col key={skill.id}>
								<i className={`${skill.icon} colored`} />
							</Col>
						))}
					</Row>
				))}
			</Container>
		</div>
	);
}

export default Skills;