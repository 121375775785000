import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Route, Redirect, Switch } from "react-router";

import Home from '../component/home/Home';
import Projects from '../component/projects/Projects';
import PageNotFound from '../component/pageNotFound/PageNotFound';
import ScrollToTop from './ScrollToTop';

function SwitchRouter() {

	return (
		<div>
			<ScrollToTop>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route path='/projects' component={Projects} />
					<Route path='/404' component={PageNotFound} />
					<Redirect to="/404" />
				</Switch>
			</ScrollToTop>
		</div>
	);
}

export default SwitchRouter;