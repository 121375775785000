import React, { useState, useEffect, useRef } from 'react';
import BIRDS from '../../../node_modules/vanta/dist/vanta.birds.min.js';
import './HomeTop.scss';

function HomeTop() {

	const [vantaEffect, setVantaEffect] = useState(0);
	const myRef = useRef(null);

	useEffect(() => {
		if (!vantaEffect) {
			setVantaEffect(BIRDS({
				el: myRef.current,
				color: 0x000000,
				color1: 0x1C3C37,
				color2: 0x085684,
				backgroundColor: 0x0,
				backgroundAlpha: 0,
				quantity: 5,
				birdSize: 1,
				wingSpan: 30,
				speedLimit: 5,
				separation: 20,
				alignment: 20,
				cohesion: 20
			}))
		}
		return () => {
			if (vantaEffect) vantaEffect.destroy()
		}
	}, [vantaEffect]);

	return (
		<div className="HomeTopBody" ref={myRef}>
			<div className="me">
				<div className="typewriter-container">
					<p className="typewriter">&gt; I'm Hrag Ayvazian</p>
				</div>
			</div>
		</div>
	);
}

export default HomeTop;