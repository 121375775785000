import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';

import Hamburger from 'hamburger-react'


function NavbarCollapse() {

	const [navState, setNavState] = useState(false);

		return (
			<div className="NavbarCollapse">
				<Hamburger toggled={navState} toggle={setNavState} />
				{navState ?
					<div className="showCollapseMenuItems">
						<NavLink onClick={() => setNavState(!navState)}
							className="collapseMenuItem" to="/projects"
							activeStyle={{ color: '#DAA520' }}>
								Projects
						</NavLink>
					</div> : ''
				}
			</div>
		);
}

export default NavbarCollapse;