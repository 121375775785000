import React, { useState } from 'react';
import './AboutMe.scss';

function AboutMe() {

	const [aboutMeIcons] = useState([
		{
			id: 1,
			icon: 'fa fa-github',
			title: 'View my GitHub',
			name: '',
			link: "https://github.com/HragA",
			alt: "My Github"
		},
		{
			id: 2,
			icon: 'fa fa-linkedin-square',
			title: 'View my LinkedIn',
			name: '',
			link: "https://www.linkedin.com/in/hrag-ayvazian/",
			alt: "My LinkedIn"
		},
		{
			id: 3,
			icon: 'fa fa-envelope',
			title: 'Email Me',
			name: '',
			link: "mailto:hraghovsep@gmail.com",
			alt: "Email Me"
		}
	]);

	return (
		<div className="AboutMeBody">
			<h1>AboutMe</h1>
			<p>
				Hi! I am a software developer who loves problem solving and a
				good challenge! I graduated from the California State
				University, Northridge in 2018 with a Bachelor's of Science
				in Computer Science. Throughout my education and beyond, I
				have enjoyed learning new coding languages and programming for
				fun. Some of my work can be found on my GitHub repository.
				Please feel free to check it out!
				<br/> <br/>
				Outside of coding, I like to enjoy the outdoors, play soccer,
				travel, spend time with family, and play video games.
			</p>
			{aboutMeIcons.map(icon => (
				<a
					key={icon.id}
					href={icon.link}
					target="_blank"
					alt={icon.alt}
					rel="noopener noreferrer"
				>
					<i
						className={icon.icon}
						data-toggle="tooltip"
						data-placement="top"
						title={icon.title}
						rel="noopener noreferrer"
					>
					</i>
						&nbsp;{icon.name}
				</a>
			))}
		</div>
	);
}

export default AboutMe;